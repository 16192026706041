/***************************************************
  Imports
***************************************************/

import React from "react"

/***************************************************
  Component
***************************************************/

export default ({ items, flex, className }) => {
  return (
    <ul className={`list ${flex && "flex"} ${className && className}`}>
      {items.map((item, index) => {
        return (
          <li key={index}>
            {item.title && <h3>{item.title}</h3>}
            <p>{item.text}</p>
          </li>
        )
      })}
    </ul>
  )
}
