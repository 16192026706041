/***************************************************
  Imports
***************************************************/

import React from "react"

/***************************************************
  Component
***************************************************/

export default ({ number, text, className, ...props }) => {
  return (
    <div {...props} className={`page-number ${className}`}>
      <span>{number}</span>
      <div></div>
      <span>{text}</span>
    </div>
  )
}
