/***************************************************
  Imports
***************************************************/

import React from "react"
import PageTypeI from "../../templates/PageTypeI"
import List from "../../components/List"

import TitleImg from "../../images/simcrest-apps-time-and-billing.png"

import IconManual from "../../images/icon-manual.svg"
import IconLicense from "../../images/icon-license.svg"
import IconPrivacy from "../../images/icon-privacy.svg"

/***************************************************
  Page Data
***************************************************/

const summaryInfo = () => {
  return (
    <div className="simcrest-apps summary-info">
      <p className="spread">
        Register time and bill customers with this easy-to-use app that also
        includes resource scheduling.
        <br />
        <br />
        <i>
          Do you lack control of time registration for your billable resources?
          Are you billing less due to late or lacking time registration? Is your
          billing to customers complex and error-prone? Would you like to take
          control of your capacity planning of billable resources?
        </i>
        <br />
        <br />
        Making sure all billable time is entered in a timely manner is critical
        to cashflow and customer satisfaction. Catching issues with time entry
        early is crucial so corrections can be made before billing is taking
        place. Scheduling of resources can help you with capacity planning and
        resource availability.
        <br />
        <br />
        Our Time and Billing App makes time entry and billing customers
        effortless. Time registration is a controlled process that reduce
        mistakes and errors. Both time entry and billing follow predefined rules
        to avoid faulty billing of your customers. Resource time overviews allow
        control of billing and missing time entry is easily discovered and
        corrected. Billing is a one-click process that creates invoices to your
        customers based on their individual requirements. On top of all this we
        also provide an easy-to-use resource scheduling system that is closely
        linked to the time entry system.
      </p>
      <h5>Features and Benefits</h5>
      <List
        flex
        className="concise dark"
        items={[
          {
            text: `Save time registering time and billing clients.`,
          },
          {
            text: `Time entry that makes sure work is entered quickly and correctly.`,
          },
          {
            text: `One-click billing of all jobs to customers using predefined rules.`,
          },
          {
            text: `Handle project prepayments with ease.`,
          },
          {
            text: `Overview of resource time entry catches missing time and potential issues quickly.`,
          },
          {
            text: `Resource scheduling helps you plan and control resource availability.`,
          },
        ]}
      />
      <div>
        <img src={TitleImg} />
        <div>
          <p>
            SimCrest Time and Billing is easy to use and saves you a lot of
            time!
            <br />
            <br />
            Time and Billing can be acquired as an App for Dynamics 365 Business
            Central On Premise only (no online version available).
          </p>
        </div>
      </div>
    </div>
  )
}

/***************************************************
  Component
***************************************************/

export default () => {
  return (
    <PageTypeI
      title="Time and Billing"
      description="Register time and bill customers with this easy-to-use app that also includes resource scheduling."
      linkSubPageGroupID="SimCrestAppsGroup"
      subTitle="Time and Billing for Microsoft Dynamics 365 Business Central"
      summaryInfo={summaryInfo}
      documents={[
        {
          title: "Manual",
          link: "/docs/manual-time-and-billing.pdf",
          icon: IconManual,
        },
        {
          title: "License",
          link: "/docs/license-time-and-billing.pdf",
          icon: IconLicense,
        },
        {
          title: "Privacy",
          link: "/docs/privacy-time-and-billing.pdf",
          icon: IconPrivacy,
        },
      ]}
    />
  )
}
